import { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CAMPAIGN_POSITION,
  COMPETITIONS,
  IN_CAMPAIGN,
  JOIN_CAMPAIGN,
  LEADERBOARDS,
} from "../../../../GraphQL/competitions";
import { useUser } from "../../../../Hooks/useUser";
import {
  IExistsQuery,
  TLeaderboards,
  TMyLeaderboardDetails,
} from "../../@types";

export const CampaignSlugViewModel = (slug: string) => {
  const { allstarUser } = useUser();
  const [error, setError] = useState<boolean>(false);
  const [myCampaign, setMyCampaign] = useState<null | TMyLeaderboardDetails>(
    null,
  );
  const [reqCampaignPosition] = useLazyQuery(CAMPAIGN_POSITION, {
    fetchPolicy: "cache-and-network",
  });
  const query = { campaign: slug };
  const { loading, data } = useQuery(COMPETITIONS, {
    fetchPolicy: "no-cache",
    onError: () => {
      setError(true);
    },
    variables: query,
  });

  const { data: exists, loading: existsLoading } = useQuery(IN_CAMPAIGN, {
    variables: query,
  });

  const { data: leaderboards } = useQuery(LEADERBOARDS, {
    fetchPolicy: "network-only",
    onError: () => {
      setError(true);
    },
    variables: query,
  });

  const [joinLeaderboard] = useMutation(JOIN_CAMPAIGN);

  useEffect(() => {
    if (
      exists?.inCampaign?.exists &&
      leaderboards?.leaderboards?.users &&
      allstarUser.loggedIn
    ) {
      const mapped = leaderboards.leaderboards.users.map(
        (x: { user: { _id: string } }) => x?.user?._id,
      );

      if (!mapped.includes(allstarUser.user._id)) {
        getPosition();
      } else setMyCampaign(null);
    } // eslint-disable-next-line
  }, [leaderboards, exists]);

  const getPosition = async () => {
    const { data } = await reqCampaignPosition({ variables: query });
    const mapped = leaderboards.leaderboards.users.map(
      (x: { user: { _id: string } }) => x?.user?._id,
    );

    if (!mapped.includes(allstarUser.user._id)) {
      setMyCampaign(data?.inCampaignPosition);
    } else setMyCampaign(null);
  };

  return {
    data,
    loading,
    error,
    allstarUser,
    joinLeaderboard,
    leaderboard: leaderboards?.leaderboards as TLeaderboards,
    myCampaign: myCampaign as TMyLeaderboardDetails,
    exists: exists?.inCampaign as IExistsQuery | undefined,
    existsLoading,
  };
};
