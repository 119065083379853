import { gql } from "@apollo/client";

export const SUGGESTED_CREATORS = gql`
  query SuggestedCreators($count: Int) {
    suggestedCreators(count: $count) {
      user {
        username
        _id
        avatar
      }
      reason
      playedGameId
      lastPlayedAt
      followedBy {
        username
        avatar
      }
    }
  }
`;
