import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import type { IconType } from "react-icons";
import { BiSolidHot, BiSolidStar } from "react-icons/bi";
import { colors } from "../../../../theme/colors";

const BadgeContainer = styled(Flex)`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  gap: 4px;
  left: 4px;
  padding: 4px 4px;
  position: absolute;
  top: 4px;
  z-index: 2;
`;

const BadgeIcon = styled(Box)<{ color: string }>`
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 1px;
`;

const BadgeText = styled(Text)<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 1px 2px rgba("${({ color }) => color}", 0.5);
`;

const BADGE_ICONS = {
  ALLSTAR_SCORE: BiSolidStar,
  HOT_NEW: BiSolidHot,
};

const BADGE_TEXT = {
  ALLSTAR_SCORE: "High Allstar Score",
  HOT_NEW: "Fresh & Hot",
};

const BADGE_COLORS = {
  ACHIEVEMENT: colors.goldStar,
  ALGORITHM: colors.soHot,
};

export interface IContentBadge {
  icon: IconType;
  text: string;
  color: string;
}

interface IContentItemBadgeProps {
  badge?: IContentBadge;
}

export const CONTENT_BADGES: Record<keyof typeof BADGE_ICONS, IContentBadge> = {
  ALLSTAR_SCORE: {
    icon: BADGE_ICONS.ALLSTAR_SCORE,
    text: BADGE_TEXT.ALLSTAR_SCORE,
    color: BADGE_COLORS.ACHIEVEMENT,
  },
  HOT_NEW: {
    icon: BADGE_ICONS.HOT_NEW,
    text: BADGE_TEXT.HOT_NEW,
    color: BADGE_COLORS.ALGORITHM,
  },
};

export const ContentItemBadge = ({ badge }: IContentItemBadgeProps) => {
  if (!badge) return <></>;

  const { icon, text, color } = badge;

  return (
    <BadgeContainer>
      <BadgeIcon color={color}>{icon({ color: colors.ice })}</BadgeIcon>
      <BadgeText color={colors.ice}>{text}</BadgeText>
    </BadgeContainer>
  );
};
