import { atom } from "recoil";
import { isMobile } from "react-device-detect";

import { persistAtom } from ".";

export const SidebarExpandedState = atom({
  key: "SidebarExpanded_v1",
  default: !isMobile,
  effects_UNSTABLE: [persistAtom],
});
