import { YearEndWrap2024 } from "./2024";

interface IYearEndWrapProps {
  period?: string;
  closeModal: () => void;
}

const Wrapped = ({ period, closeModal }: IYearEndWrapProps) => {
  switch (period) {
    case "2024":
      return <YearEndWrap2024 closeModal={closeModal} />;
    default:
      return <></>;
  }
};

export { Wrapped };
