import { Redirect, Route } from "react-router-dom";
import { URLS } from "../MVVM/Utilities/Maps";

const redirects = [
  <Route exact key="subscribe" path="/subscribe">
    <Redirect to="/subscriptions" />
  </Route>,
  <Route exact key="updates" path="/updates">
    <Redirect to="/upgrade" />
  </Route>,
  <Route exact key="referred" path="/referred">
    <Redirect to="/signup" />
  </Route>,
  <Route exact key="myallstar" path="/myallstar">
    <Redirect to={`/dashboard${window.location.search}`}></Redirect>
  </Route>,
  <Route exact key="culture" path="/culture">
    <Redirect to={"/careers"}></Redirect>
  </Route>,
  <Route exact key="pro" path={"/pro"}>
    <Redirect to={URLS.UPGRADE}></Redirect>
  </Route>,
  <Route exact key="partnerships" path={"/partnerships"}>
    <Redirect to="/partners"></Redirect>
  </Route>,
  <Route key="clips" path="/clips">
    <Redirect to="/"></Redirect>
  </Route>,
  <Route exact key="montages" path="montages">
    <Redirect to={"/"}></Redirect>
  </Route>,
  <Route exact key="allstarbot" path="/allstarbot">
    <Redirect to={"/dashboard"}></Redirect>
  </Route>,
  <Route exact key="signup" path="/signup">
    <Redirect to={"/register"}></Redirect>
  </Route>,
  <Route exact key="undefinedWrapped" path="/wrapped/2024">
    <Redirect to={"/"}></Redirect>
  </Route>,
];

export { redirects };
