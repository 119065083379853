import { Box } from "rebass/styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";

import { SectionTitle } from "../styledComponents";
import { SuggestedItem } from "../SuggestedItem";
import { HomepageViewModel } from "../ViewModel";
import { ISuggestedUser } from "../@types";
import { Loading } from "../Loading";

const SuggestedFeed = () => {
  const { allstarUser, suggested, suggestedLoading } = HomepageViewModel();

  if (suggestedLoading) return <Loading />;
  if (!suggested) return <></>;

  return (
    <Box>
      <SectionTitle mb={4}>Suggested</SectionTitle>
      <Swiper
        centeredSlides={false}
        spaceBetween={32}
        slidesPerView="auto"
        mousewheel={true}
        modules={[Mousewheel, Pagination, Navigation]}
      >
        {suggested.map((suggested: ISuggestedUser, index: number) => (
          <SwiperSlide key={index} style={{ width: "auto" }}>
            <SuggestedItem suggested={suggested} allstarUser={allstarUser} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export { SuggestedFeed };
