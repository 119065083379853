export const NAV_LINKS = {
  accountSettings: "/accountsettings",
  company: "/about",
  competitions: "/competitions",
  following: "/profile?view=Following",
  login: "/login",
  matchHistory: "/match-history",
  montages: "/montages/create",
  partners: "https://developer.allstar.gg",
  profile: "/u",
  root: "/",
  studio: "/studio",
  support: "https://help.allstar.gg/hc/en-us/requests/new",
  terms: "/legal/terms-of-use",
  upgrade: "/upgrade",
};

export const isActive = (urls: string[]) =>
  urls.includes(`/${window.location.pathname.split("/")[1]}`);
