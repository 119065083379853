import { Box, Flex } from "rebass";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { NAV_LINKS, isActive } from "../utils";
import {
  navLinkActiveFill,
  NavLinkText,
  StyledBiCompass,
  StyledBiDotsHorizontalRounded,
  StyledBiUser,
  StyledBiPalette,
  StyledBiTrophy,
  StyledBiVideo,
  StyledDivider,
  StyledHistoryLogo,
} from "../styledComponents";
import { Tooltip } from "../../Tooltip";
import { useUser } from "../../../Hooks/useUser";
import { usePortal } from "../../../Hooks/usePortal";
import { PartnerTypeModal } from "../../Modals/PartnerType";

interface ISidebarCollapsedProps {
  competitionLink?: string;
}

const SidebarCollapsed = ({ competitionLink }: ISidebarCollapsedProps) => {
  const { allstarUser } = useUser();
  const username = allstarUser?.user?.profile?.username;
  const profileLink = username
    ? `${NAV_LINKS.profile}/${username}`
    : NAV_LINKS.login;

  return (
    <SidebarContainerCollapsed>
      <Flex flexDirection="column" alignItems="center" style={{ gap: 8 }}>
        <NavLink to={NAV_LINKS.root} isActive={isActive([NAV_LINKS.root])}>
          <StyledBiCompass />
        </NavLink>
        <NavLink
          to={profileLink}
          isActive={isActive(["/u", NAV_LINKS.profile])}
        >
          <StyledBiUser />
        </NavLink>
        <StyledDivider />
        <NavLink
          to={NAV_LINKS.matchHistory}
          isActive={isActive([NAV_LINKS.matchHistory])}
        >
          <StyledHistoryLogo />
        </NavLink>
        <NavLink to={NAV_LINKS.studio} isActive={false}>
          <StyledBiPalette />
        </NavLink>
        <NavLink to={NAV_LINKS.montages} isActive={false}>
          <StyledBiVideo />
        </NavLink>
        <StyledDivider />
        {competitionLink && (
          <>
            <NavItem>
              <NavLink
                to={`/competitions/${competitionLink}/play`}
                isActive={false}
              >
                <StyledBiTrophy />
              </NavLink>
            </NavItem>
            <StyledDivider />
          </>
        )}
        <StyledTooltip
          type="click"
          tooltipContent={<SubNav />}
          position={{
            top: "calc(100% - 80px)",
            left: "64px",
          }}
        >
          <StyledBiDotsHorizontalRounded />
        </StyledTooltip>
      </Flex>
    </SidebarContainerCollapsed>
  );
};

const SubNav = () => {
  const { portalOpen, setPortalOpen, Portal } = usePortal(<PartnerTypeModal />);
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ gap: 8 }}
    >
      {portalOpen && Portal}
      <NavItem>
        <SubNavLink to={NAV_LINKS.upgrade} isActive={false}>
          <NavLinkText>Upgrade</NavLinkText>
        </SubNavLink>
      </NavItem>
      <NavItem>
        <SubNavLink to="#" onClick={() => setPortalOpen(true)} isActive={false}>
          <NavLinkText>For Partners</NavLinkText>
        </SubNavLink>
      </NavItem>
      <NavItem>
        <SubNavLink
          to="#"
          onClick={() => window.location.replace(NAV_LINKS.support)}
          isActive={false}
        >
          <NavLinkText>Support</NavLinkText>
        </SubNavLink>
      </NavItem>
    </Flex>
  );
};

const NavItem = styled(Flex)`
  color: ${({ theme }) => theme.colors.chalk} !important;
  text-decoration: none;
  width: 100%;
`;

const SidebarContainerCollapsed = styled(Box)`
  background-color: ${({ theme }) => theme.colors.darkerNed};
  padding: 16px;
  position: relative;
  width: 84px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

const navLinkStyle = css<{ isActive: boolean }>`
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 12px 0;
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  ${navLinkStyle}

  :hover {
    background: ${({ theme }) => theme.colors.ned};

    svg * {
      color: ${({ theme }) => theme.colors.envy};
    }
  }
`;

const NavLink = styled(Link)`
  ${navLinkStyle}
  text-decoration: none;
  padding: 12px;

  ${(props) => (props.isActive ? navLinkActiveFill : "")}

  :hover {
    ${navLinkActiveFill}
  }
`;

const SubNavLink = styled(NavLink)`
  text-decoration: none;
  justify-content: flex-start;
`;

export { SidebarCollapsed };
