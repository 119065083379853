import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";

import { FollowButton } from "../../../Components/Button";
import { AvatarWrapper, getAvatarBorderLevel } from "../../../Utilities/User";
import { Avatar } from "../../../Components/Avatar";
import { ISuggestedUser } from "../@types";
import { useMutateFollowers } from "../../../Hooks/useMutateFollowers";
import { TMutateFollowers } from "../../../Hooks/@types";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { PlayedTogether } from "./PlayedTogether";
import { FollowedByFollowing } from "./FollowedByFollowing";
import { IAllstarUser } from "../../Setup/Components/@types";

interface ISuggestedItemProps {
  allstarUser: IAllstarUser;
  suggested: ISuggestedUser;
}

const SuggestedItem = ({ allstarUser, suggested }: ISuggestedItemProps) => {
  const avatarBorderLevel = getAvatarBorderLevel(suggested.user);
  const history = useHistory();
  const { mutateFollowers } = useMutateFollowers();
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);
  const followUnfollow = (data: TMutateFollowers) => {
    return mutateFollowers(data);
  };
  const setFollowModal = ({
    displayName,
    avatar,
  }: {
    displayName: string;
    avatar: string;
  }) => {
    setAllstarModalState({
      ...allstarModalState,
      isOpen: ModalType.Signup,
      data: {
        target: displayName,
        avatar: avatar,
        action: "follow",
      },
    });
  };

  const { user } = suggested;

  return (
    <SuggestedItemWrapper>
      <SuggestedItemContainer>
        <AvatarClickable
          onClick={() => {
            history.push(`/u/${user.username}`);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          <AvatarWrapper size="large" borderLevel={avatarBorderLevel}>
            <Avatar image={user.avatar} size="large" />
          </AvatarWrapper>
        </AvatarClickable>
        <Text
          fontWeight={700}
          fontSize="4"
          onClick={() => {
            history.push(`/u/${user.username}`);
            window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {user.username ?? "User"}
        </Text>
        <Box height="30px">
          {suggested.reason === "PLAYED_TOGETHER" && (
            <PlayedTogether
              playedGameId={suggested.playedGameId}
              lastPlayedAt={suggested.lastPlayedAt}
            />
          )}
          {suggested.reason === "FOLLOWED_BY_FOLLOWING" && (
            <FollowedByFollowing followedBy={suggested.followedBy} />
          )}
        </Box>
        <FollowButton
          size="tiny"
          flex={true}
          openModal={true}
          isModal={false}
          followUnfollow={followUnfollow}
          setFollowModal={setFollowModal}
          userId={user._id}
          allstarUser={allstarUser}
          displayName={user.username}
          avatar={user.avatar}
        />
      </SuggestedItemContainer>
    </SuggestedItemWrapper>
  );
};

const AvatarClickable = styled(Box)`
  cursor: pointer;
`;

const SuggestedItemWrapper = styled(Box)`
  max-width: 220px;
  width: 220px;
`;

const SuggestedItemContainer = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 12px;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 12px 24px 24px;
  width: auto;
`;

export { SuggestedItem };
