import { TFeaturedItemType } from "../../Views/Homepage/Featured/@types";

export const analyticsClickEvent = (type: TFeaturedItemType, name?: string) => {
  switch (type) {
    case "promotion":
      window.rudderanalytics.track("Promotion - Button Click", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    case "motw":
      window.rudderanalytics.track("Montage of the Week - Button Click", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    case "cotd":
      window.rudderanalytics.track("Clip of the Day - Button Click", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    default:
      break;
  }
};

export const analyticsSlideEvent = (type: TFeaturedItemType, name?: string) => {
  switch (type) {
    case "promotion":
      window.rudderanalytics.track("Promotion - Impression", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    case "motw":
      window.rudderanalytics.track("Montage of the Week - Impression", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    case "cotd":
      window.rudderanalytics.track("Clip of the Day - Impression", {
        type: "carouselSlide",
        promotionName: name || "",
      });
      break;
    default:
      break;
  }
};
