import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { PinClipButton } from "./PinClipButton";
import { PinnableViewModel } from "./ViewModel";
import { useState } from "react";

export const Pinnable = ({
  clipId,
  userId,
  hasBeenPinned,
  children,
}: {
  clipId: string;
  userId: string;
  hasBeenPinned: boolean;
  children: React.ReactNode;
}) => {
  const { editPlaylist } = PinnableViewModel({ userId: userId });

  return (
    <PinnedWrapper>
      {clipId && (
        <PinnedButtonWrapper className="pinned-wrapper">
          <PinClipButton
            hasBeenPinned={hasBeenPinned}
            clipId={clipId}
            editPlaylist={editPlaylist}
            isVisible={true}
          />
        </PinnedButtonWrapper>
      )}
      {children}
    </PinnedWrapper>
  );
};
const PinnedButtonWrapper = styled(Box)`
  position: absolute;
  z-index: 20;
  top: 5px;
  left: 5px;
  opacity: 0.5;
  cursor: pointer;
`;

const PinnedWrapper = styled(Flex)`
  position: relative;
  width: 100%;

  &:hover .pinned-wrapper {
    opacity: 1;
  }
`;
