import { IEmptyContentItem } from "../@types";
import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

export const EmptyContentItem = ({ item }: { item: IEmptyContentItem }) => {
  return (
    <>
      <ResponsiveContainer>
        <Content>
          {item.icon}
          <Text
            variant={"text.labelXXSmall"}
            maxWidth={160}
            textAlign={"center"}
          >
            {item.text}
          </Text>
        </Content>
      </ResponsiveContainer>
    </>
  );
};

const ResponsiveContainer = styled(Box)`
  aspect-ratio: 16/9;
  position: relative;
  height: auto;
  width: 100%;
`;

const Content = styled(Flex)`
  border: 2px dashed var(--Chalk-40, rgba(143, 169, 175, 0.4));
  color: ${(props) => props.theme.colors.chalk};
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    max-width: 100%;
    width: 100%;
  }
`;
