import { Flex, Text } from "rebass";
import styled from "styled-components";

import { Button } from "../../Button";
import { FaCode } from "react-icons/fa";
import { BiJoystick } from "react-icons/bi";
import { HiOutlineBadgeCheck } from "react-icons/hi";
import { NAV_LINKS } from "../../Sidebar/utils";
import theme from "../../../../theme/themeThree";

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: ${(props) => props.theme.colors.ned};
  border: solid 1px ${(props) => props.theme.colors.chalk};
  margin: 1rem 0 0 0;
  &:hover {
    background-color: #14212A;
    border: solid 1px ${(props) => props.theme.colors.envy};
  }
`;

const StyledCodeIcon = styled(FaCode)`
  padding: 1rem;
  color: ${(props) => props.theme.colors.envy};
`;

const StyledJoyStickIcon = styled(BiJoystick)`
  padding: 1rem;
  color: ${(props) => props.theme.colors.envy};
`;

const StyledBadgeCheckIcon = styled(HiOutlineBadgeCheck)`
  padding: 1rem;
  color: ${(props) => props.theme.colors.envy};
`;

const StyledModalWrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.ned};
  padding: 24px;
  border-radius: 8px;
`;

const StyledContentWrapper = styled(Flex)`
  padding: 1rem;
  text-align: left;
`;

const ButtonTitle = styled(Text)`
  font-weight: 700; 
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.ice};
  word-wrap: break-word;
  white-space: normal;
`;

const ButtonSubTitle = styled(Text)`
  font-weight: 500;
  font-size: 14px; 
  line-height: 20px;
  color: ${(props) => props.theme.colors.chalk};
  word-wrap: break-word;
  white-space: normal;
`;

export const PartnerTypeModal = () => {
  return (
    <StyledModalWrapper flexDirection="column" m={2}>
      <Text fontWeight={700} fontSize={24} lineHeight="32px" color={theme.colors.ice}>How do you want to partner with Allstar?</Text>
      <Text fontWeight={500} fontSize={16} lineHeight="20px" color={theme.colors.chalk}>Tell us how you'd like to work with Allstar so that we can direct you to the right spot:</Text>
      <Flex flexDirection="column" pt={1}>
        <StyledButton onClick={() => window.open(NAV_LINKS.partners, "_blank")}>
          <StyledCodeIcon size={33} />
          <StyledContentWrapper flexDirection="column">
            <ButtonTitle>I want to integrate highlights into my platform.</ButtonTitle>
            <ButtonSubTitle>I am a developer of a CS2, League of Legends, or Fortnite platform looking to integrate highlights.</ButtonSubTitle>
          </StyledContentWrapper>
        </StyledButton>
        <StyledButton onClick={() => window.open("https://playsharestar.typeform.com/to/xIqbg6cn", "_blank")}>
          <StyledJoyStickIcon size={33} />
          <StyledContentWrapper flexDirection="column">
            <ButtonTitle>I want to integrate highlights into my game.</ButtonTitle>
            <ButtonSubTitle>I am a game developer looking to integrate highlights in my game.</ButtonSubTitle>
          </StyledContentWrapper>
        </StyledButton>
        <StyledButton onClick={() => window.open("https://playsharestar.typeform.com/to/kSRxIWR0", "_blank")}>
          <StyledBadgeCheckIcon size={33} />
          <StyledContentWrapper flexDirection="column">
            <ButtonTitle>I want to partner with Allstar as a content creator.</ButtonTitle>
            <ButtonSubTitle>I am a content creator looking to be verified as a partner with Allstar.</ButtonSubTitle>
          </StyledContentWrapper>
        </StyledButton>
      </Flex>
    </StyledModalWrapper>
  );
};