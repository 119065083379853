import { useRef } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { Link, Redirect } from "react-router-dom";

import { ReactComponent as HeaderLogo } from "../../../../assets/images/logo/headerLogo.svg";
import { VideoPlayer } from "../../../VideoPlayer";
import { Avatar } from "../../../Components/Avatar";
import { formatNumber } from "../util";
import { EmptyVideo } from "./EmptyVideo";
import { EmptyStats } from "./EmptyStats";
import { WrappedViewModel } from "./ViewModel";
import { Loading } from "./Loading";
import { Container, YearEndWrapContainer } from "./styledComponents";

const YearEndWrap2024 = ({ closeModal }: { closeModal: () => void }) => {
  const videoPlayerRef = useRef(null);
  const username = window.location.pathname.split("/").pop() || "";
  const closeAndRedirect = () => {
    closeModal();
    return <Redirect to="/"></Redirect>;
  };

  const { data, loading, error } = WrappedViewModel({ username });

  if (loading) return <Loading />;
  if (error || !data.wrappedSummary) return closeAndRedirect();

  const { wrappedSummary } = data;
  const eligible = !!wrappedSummary.clip?.url;

  const { REACT_APP_S3_CLIP_BUCKET } = process.env;

  return (
    <Container id="main">
      <YearEndWrapContainer
        background={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/background.png`}
      >
        <StyledHeaderLogo />
        <StyledHeading>Reloaded</StyledHeading>
        <StyledHeading2>2024</StyledHeading2>
        <VideoContainer>
          <VideoWrapper>
            {eligible ? (
              <VideoPlayer
                refForVideoPlayer={videoPlayerRef}
                mp4Source={wrappedSummary.clip?.url}
                playAnalyticsProperties={{
                  shareId: wrappedSummary.clip?.shareId,
                  clipId: wrappedSummary.clip?.id,
                  clipOwner: username,
                  total_views: wrappedSummary.clip?.views,
                }}
                watchAnalyticsProperties={{
                  collection: wrappedSummary.clip?.title,
                  collectionType: "Wrapped",
                  item: wrappedSummary.clip?.shareId,
                  itemType: "Clip",
                  action: "watch",
                }}
                options={{
                  controls: false,
                  loop: true,
                  muted: true,
                  aspectRatio: "16:9",
                  poster: wrappedSummary.thumbnailUrl,
                  playsinline: true,
                  autoplay: "muted",
                  userActions: {
                    click: false,
                  },
                }}
              />
            ) : (
              <EmptyVideo />
            )}
          </VideoWrapper>
        </VideoContainer>
        <StatSection>
          {eligible ? (
            <>
              <Column>
                <Flex flexDirection="column" alignItems="center">
                  <ColumnTitle>Your</ColumnTitle>
                  <ColumnSubTitle>Content Generated</ColumnSubTitle>
                </Flex>
                <Flex flexDirection="column" style={{ gap: 20 }}>
                  <Section>
                    <StatText>
                      {formatNumber(wrappedSummary.totalViews)}
                    </StatText>
                    <StyledText>Total Views</StyledText>
                  </Section>
                  <Section>
                    <StatText>
                      {formatNumber(
                        Math.floor(wrappedSummary.watchTime / 60 / 60),
                      )}
                    </StatText>
                    <StyledText>Hours of Watch time</StyledText>
                  </Section>
                  <Section>
                    <StatText>
                      {formatNumber(wrappedSummary.clipReactions)}
                    </StatText>
                    <StyledText>Total Reactions</StyledText>
                  </Section>
                </Flex>
              </Column>
              <ColumnAlt>
                <Flex flexDirection="column" alignItems="center">
                  <ColumnTitle>Allstar</ColumnTitle>
                  <ColumnSubTitle>Saved You</ColumnSubTitle>
                </Flex>
                <Flex flexDirection="column" style={{ gap: 20 }}>
                  <Section>
                    <StatText>{formatNumber(wrappedSummary.gbStored)}</StatText>
                    <StyledText>GB of Storage</StyledText>
                  </Section>
                  <Section>
                    <StatText>
                      {formatNumber(wrappedSummary.hoursSaved)}
                    </StatText>
                    <StyledText>Hours of Editing*</StyledText>
                  </Section>
                  <Section>
                    <StatText>
                      {formatNumber(
                        wrappedSummary.framesSaved
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      )}
                    </StatText>
                    <StyledText>Frames From Dropping*</StyledText>
                  </Section>
                </Flex>
              </ColumnAlt>
            </>
          ) : (
            <EmptyStats />
          )}
        </StatSection>
      </YearEndWrapContainer>
      <UserSection>
        <Link
          to={`/u/${username}`}
          style={{ textDecoration: "none" }}
          color="ice"
        >
          <Flex alignItems="center" style={{ gap: 12 }}>
            <Avatar size="small" image={wrappedSummary?.user?.avatarUrl} />
            <Flex flexDirection="column" style={{ gap: 4 }}>
              <Text fontSize="4" fontWeight="700" color="ice">
                {username}
              </Text>
              <Text fontSize="1" color="ice">
                {`http://allstar.gg/wrapup/2024/${username}`}
              </Text>
            </Flex>
          </Flex>
        </Link>
        {eligible && (
          <Footnote>
            <Text fontSize={[2, 2, 6]}>*</Text>
            <Text fontSize="0">estimated based off</Text>
            <Text fontSize="0">the average user </Text>
          </Footnote>
        )}
      </UserSection>
    </Container>
  );
};

const VideoWrapper = styled(Box)`
  border: 6px solid ${({ theme }) => theme.colors.envy};
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  z-index: 2;
`;

const StatSection = styled(Flex)`
  gap: 36px;
  justify-content: space-evenly;
  width: 100%;
`;

const UserSection = styled(Flex)`
  align-items: center;
  background: #312f51;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap: 20px;
  padding: 20px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const columnStyle = css`
  flex-direction: column;
  gap: 20px;
`;

const Column = styled(Flex)`
  ${columnStyle}
  color: ${({ theme }) => theme.colors.envy};
  align-items: center;
  justify-content: space-between;
`;

const ColumnAlt = styled(Flex)`
  ${columnStyle}
  color: ${({ theme }) => theme.colors.legendary};
  align-items: center;
  justify-content: space-between;
`;

const VideoContainer = styled(Box)`
  margin: 20px 0 30px 0;
  position: relative;
  width: 100%;
`;

const headingCss = css`
  font-family: "Road Rage";
  font-weight: 400;
  text-transform: uppercase;
`;

const StyledHeading = styled(Text)`
  ${headingCss}
  color: ${({ theme }) => theme.colors.envy};
  font-size: ${({ theme }) => theme.fontSizes[8]};
`;

const StyledHeading2 = styled(Text)`
  ${headingCss}
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[9]};
  line-height: 0.5em;
  z-index: 9;
`;

const Section = styled(Flex)`
  flex-direction: column;
  text-align: center;
`;

const Footnote = styled(Flex)`
  color: ${({ theme }) => theme.colors.ice};
  flex-direction: column;
  gap: 0;
  line-height: 1em;
  margin-left: auto;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    flex-direction: row;
    font-size: ${({ theme }) => theme.fontSizes[4]};
    gap: 3px;
    margin-left: 0px;
  }
`;

const ColumnTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-weight: 700;
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const ColumnSubTitle = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

const StatText = styled(Text)`
  font-family: "Road Rage";
  font-size: ${({ theme }) => theme.fontSizes[8]};
  text-transform: uppercase;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[6]};
  }
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 700;
`;

const StyledHeaderLogo = styled(HeaderLogo)`
  height: 18px;
  margin-bottom: 0px;
`;

export { YearEndWrap2024 };
