import { useIsDesktopApp } from "./MVVM/Hooks/useIsDesktopApp";
import { useIsMobileApp } from "./MVVM/Hooks/useIsMobileApp";
import SiteFooter from "./components/organisms/footer";
import { MINIMAL_ROUTES } from "./routes/utils";

const Footer = (props, { showModal }) => {
  const isDesktopView = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();
  const path = window.location.pathname;

  if (
    MINIMAL_ROUTES.includes(path) ||
    isDesktopView.isDesktop ||
    isMobileApp.isMobileApp ||
    path.includes("partner/claim") ||
    path.includes("partner/signup") ||
    path.includes("setup") ||
    path.includes("signup") ||
    path.includes("register") ||
    path.includes("login") ||
    path.includes("studio") ||
    path.includes("remix") ||
    path.includes("oauth") ||
    path.includes("/clip") ||
    path.includes("/match-history") ||
    path.includes("/add-share-codes") ||
    path.includes("authenticate/mobile") ||
    path.includes("iframe")
  )
    return <></>;

  return <SiteFooter flexShrink={0} {...props} showModal={showModal} />;
};

export { Footer };
