import { Flex, Image, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const EmptyVideo = () => (
  <>
    <EmptyVideoContainer>
      <Flex>
        <StyledPlay
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/play.png`}
        />
        <StyledShare
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/share.png`}
        />
        <StyledStar
          src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/star.png`}
        />
      </Flex>
      <StyledText>Beep Bloop, You Forgot to Reload!</StyledText>
      <StyledSubText>
        Hey there, thanks for checking out the Reloaded 2024 Recap from Allstar.
        Unfortunately you didn't have any activity in the last year so we have
        nothing to "re-load" for you 😢
      </StyledSubText>
      <StyledSubText>Better luck next time!</StyledSubText>
    </EmptyVideoContainer>
  </>
);

const EmptyVideoContainer = styled(Flex)`
  align-items: center;
  background: #22242d;
  flex-direction: column;
  gap: 20px;
  padding: 60px;
  text-align: center;
`;

const iconCss = css`
  height: 50px;
`;

const StyledShare = styled(Image)`
  ${iconCss}
`;

const StyledStar = styled(Image)`
  ${iconCss}
`;

const StyledPlay = styled(Image)`
  ${iconCss}
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.colors.envy};
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[4]};
  }
`;

const StyledSubText = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

export { EmptyVideo };
