import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import videojs from "video.js";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import { useRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled, { css } from "styled-components";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import { VideoPlayer } from "../../../VideoPlayer";
import { FeaturedViewModel } from "./ViewModel";
import {
  FeaturedCardWrapper,
  FeaturedContainer,
  SectionTitle,
  StyledSlider,
  StyledSwiperSlide,
  VideoContainer,
  VideoContent,
} from "../styledComponents";
import {
  OverlayBadge,
  OverlayButton,
  OverlayTag,
} from "../../../Components/Overlays";
import { ModalType } from "../../../@types";
import {
  analyticsClickEvent,
  analyticsSlideEvent,
} from "../../../Components/Overlays/utils";
import { ClipFullModalState } from "../../../State/modals";
import { LoadingSkeleton } from "../../../Components/Skeloton";
import { Button } from "../../../Components/Button";
import { TFeaturedItemType } from "./@types";
import { FeaturedUser } from "../FeaturedUser";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

interface IFeaturedItem {
  hlsSrc: string;
  mp4Src?: string;
  title: string;
  link: string;
  type: TFeaturedItemType;
  tag: string;
  buttonCopy?: string;
  external?: boolean;
  buttonAction?: ModalType;
  shareId?: string;
  userId?: string;
  thumbnail?: string;
  avatar?: string;
  username?: string;
  views?: number;
  createdDate?: string;
}

const Featured = () => {
  const { featuredContent, loading } = FeaturedViewModel();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [impressionsTracked, setImpressionsTracked] = useState<number[]>([]);

  const handleSlideChange = (realIndex: number) => {
    if (!featuredContent.length) return setActiveIndex(0);
    setActiveIndex(realIndex);
  };

  const trackImpression = useCallback(
    (index: number) => {
      const item = featuredContent[index];
      if (!item || impressionsTracked.includes(index)) return;

      setImpressionsTracked([...impressionsTracked, index]);
      analyticsSlideEvent(item.type, item.title);
    },
    [featuredContent, impressionsTracked],
  );

  useEffect(() => {
    trackImpression(0);
  }, [trackImpression]);

  if (loading)
    return (
      <Flex width="100%" style={{ gap: 6 }} flexDirection="column">
        <LoadingSkeleton height="40px" width="20%" />
        <LoadingSkeleton height="230px" width="100%" />
      </Flex>
    );
  if (!featuredContent) return <></>;
  return (
    <Box>
      <SectionTitle mb={4}>Featured</SectionTitle>
      <FeaturedContainer mb={6} style={{ position: "relative" }}>
        <CarouselButtonLeft
          to="#"
          classification="secondaryRounded"
          className="slide-prev-featured"
        >
          <FaArrowLeft size={12} />
        </CarouselButtonLeft>
        <StyledSlider
          centeredSlides={false}
          spaceBetween={30}
          slidesPerView="auto"
          roundLengths={true}
          loop={true}
          mousewheel={true}
          navigation={{
            prevEl: ".slide-prev-featured",
            nextEl: ".slide-next-featured",
          }}
          onRealIndexChange={(slider) => {
            handleSlideChange(slider.realIndex);
            trackImpression(slider.realIndex);
          }}
          modules={[Mousewheel, Pagination, Navigation]}
        >
          {featuredContent.map((featuredItem: IFeaturedItem, index: number) => (
            <StyledSwiperSlide key={index}>
              <FeaturedCard
                item={featuredItem}
                active={activeIndex === index}
              />
            </StyledSwiperSlide>
          ))}
        </StyledSlider>
        <CarouselButtonRight
          to="#"
          classification="secondaryRounded"
          className="slide-next-featured"
        >
          <FaArrowRight size={12} />
        </CarouselButtonRight>
      </FeaturedContainer>
    </Box>
  );
};

const FeaturedCard = ({
  item,
  active,
}: {
  item: IFeaturedItem;
  active: boolean;
}) => {
  const videoPlayerRef = useRef<videojs.Player | null>(null);
  const history = useHistory();
  const mp4src = `${REACT_APP_S3_CLIP_BUCKET}/${item.mp4Src}`;
  const posterImage = `${REACT_APP_S3_CLIP_BUCKET}/${item.thumbnail}`;
  const [, setClipFullModalState] = useRecoilState(ClipFullModalState);
  const isModal = true;

  useEffect(() => {
    if (!videoPlayerRef?.current) return;

    active
      ? videoPlayerRef.current.play()?.catch(() => {})
      : videoPlayerRef.current.pause();
  }, [active]);

  return (
    <FeaturedCardWrapper height="100%">
      <VideoContainer
        clickable={true}
        onClick={() => {
          analyticsClickEvent(item.type, item.title);

          if (isMobile || !item.link.length) return;

          if (isModal && item.shareId)
            return setClipFullModalState({
              isOpen: ModalType.ClipFull,
              shareId: item.shareId,
            });

          item.external ? window.open(item.link) : history.push(item.link);
        }}
      >
        <OverlayBadge type={item.type} />
        <VideoPlayer
          refForVideoPlayer={videoPlayerRef}
          mp4Source={mp4src}
          hlsSource={item.hlsSrc}
          playAnalyticsProperties={{
            source: "Homepage Featured",
            shareId: item.shareId,
          }}
          watchAnalyticsProperties={{
            collection: "Homepage featured",
            collectionType: "Generic",
            item: item.shareId,
            itemType: "Clip",
            action: "watch",
          }}
          options={{
            autoplay: active,
            poster: posterImage,
            controls: false,
            loop: true,
            muted: true,
            playsinline: true,
            userActions: {
              click: false,
            },
          }}
        />
      </VideoContainer>
      <VideoContent p={4}>
        <Box>
          <OverlayTag
            tag={item.tag}
            style={{
              margin: "0 0 12px 0",
              alignSelf: "flex-start",
              display: "inline-block",
              borderRadius: "20px",
              fontSize: "0.7em",
            }}
          />
          <SectionTitle>
            {item.type === "cotd"
              ? "Clip of the Day"
              : item.type === "motw"
              ? "Montage of the Week"
              : item.title}
          </SectionTitle>
          <Text color="chalk" fontSize="1" fontWeight="500"></Text>
        </Box>
        {["motw", "cotd"].includes(item.type) && (
          <FeaturedUser
            avatar={item.avatar}
            username={item.username}
            views={item.views}
            createdDate={item.createdDate}
            userId={item.userId}
          />
        )}
        <OverlayButton
          buttonCopy={
            item.type === "cotd"
              ? "Go To Clip of the Day!"
              : item.type === "motw"
              ? "Check out the MotW!"
              : item.buttonCopy
          }
          external={item.external}
          link={item.link}
          name={item.title}
          type={item.type}
          buttonAction={item?.buttonAction}
          style={{
            backgroundColor: "transparent",
            border: "2px solid white",
            borderRadius: "12px",
            fontWeight: 700,
            lineHeight: "1em",
            position: "static",
          }}
        />
      </VideoContent>
    </FeaturedCardWrapper>
  );
};

const featuredButton = css`
  align-items: center;
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 16px);
  width: 32px;
  z-index: 2;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    display: none;
  }

  &.swiper-button-disabled {
    opacity: 0;
  }
`;

const CarouselButtonLeft = styled(Button)`
  ${featuredButton}
  left: -16px;
`;

const CarouselButtonRight = styled(Button)`
  ${featuredButton}
  right: -16px;
`;

export { Featured };
