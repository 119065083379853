import { useState } from "react";
import { useQuery } from "@apollo/client";
import { COMPETITIONS } from "../../GraphQL/competitions";

export const CompetitionViewModel = ({ activeOnly = false } = {}) => {
  const [error, setError] = useState<boolean>(false);

  const { loading, data } = useQuery(COMPETITIONS, {
    variables: {
      activeOnly,
    },
    onError: () => {
      setError(true);
    },
  });

  return { data, loading, error };
};
