import { Flex } from "rebass/styled-components";
import { Container, YearEndWrapContainer } from "../styledComponents";
import LoadingAnimation from "../../../../../animations/loading.json";
import Lottie from "lottie-react";

const Loading = () => {
  const { REACT_APP_S3_CLIP_BUCKET } = process.env;
  return (
    <Container>
      <YearEndWrapContainer
        background={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/background.png`}
      >
        <Flex flexDirection="column" alignItems="center" width="400px">
          <Lottie
            animationData={LoadingAnimation}
            loop={true}
            style={{ height: "32px", width: "32px" }}
          />
        </Flex>
      </YearEndWrapContainer>
    </Container>
  );
};

export { Loading };
