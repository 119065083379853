import { Flex, Image, Text } from "rebass/styled-components";
import styled from "styled-components";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const EmptyStats = () => (
  <>
    <EmptyStatsContainer>
      <StyledRobot
        src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/wrapup/2024/bot.png`}
      />
    </EmptyStatsContainer>
  </>
);

const EmptyStatsContainer = styled(Flex)`
  justify-content: center;
`;

const StyledRobot = styled(Image)`
  margin: -72px -12px 0 0;
  width: 80%;
  z-index: 4;
`;

export { EmptyStats };
