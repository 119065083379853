import { ReactNode } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Swiper } from "swiper/react";
import { Mousewheel, Pagination, Navigation } from "swiper/modules";
import { useExperiment } from "@statsig/react-bindings";

import { SectionTitle, StyledSwiperSlide } from "../styledComponents";
import {
  applyAllStarBadge,
  applyHotNewBadge,
  calculateStats,
  ContentItem,
} from "../ContentItem";
import { IContentFeedStats, IContentItem } from "../@types";
import { Loading } from "../Loading";
import { Expandable } from "../../../Components/Expandable";

interface IContentFeed {
  data: IContentItem[];
  icon?: ReactNode;
  isMontageFeed?: boolean;
  loading?: boolean;
  title: string;
  isExpandable?: boolean;
  stats?: IContentFeedStats;
}

const MAX_PER_ROW = 6;

const ContentFeed = ({
  data,
  icon,
  isMontageFeed,
  isExpandable,
  loading,
  title,
}: IContentFeed) => {
  if (loading) return <Loading />;
  if (!data) return <></>;

  return (
    <Box style={{ position: "relative" }}>
      {!isExpandable && (
        <Flex alignItems="center" mb={4}>
          <Box mt={2}>{icon}</Box>
          <SectionTitle>{title}</SectionTitle>
        </Flex>
      )}
      <Box
        mb={6}
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        <ContentFeedCarousel
          data={data.slice(0, MAX_PER_ROW)}
          title={title}
          isMontageFeed={isMontageFeed}
          stats={calculateStats(data.slice(0, MAX_PER_ROW))}
        />
      </Box>
      {data.length > MAX_PER_ROW && (
        <Expandable sectionName={title}>
          <Box pt={2}>
            <ContentFeedCarousel
              data={data.slice(MAX_PER_ROW, data.length)}
              stats={calculateStats(data.slice(MAX_PER_ROW, data.length))}
              isMontageFeed={isMontageFeed}
              title={title}
            />
          </Box>
        </Expandable>
      )}
    </Box>
  );
};

const ContentFeedCarousel = ({
  data,
  isMontageFeed,
  title,
  stats,
}: IContentFeed) => {
  const experiment = useExperiment("homepage_thumbnail_badges_");

  return (
    <Swiper
      centeredSlides={false}
      spaceBetween={16}
      slidesPerView={1}
      mousewheel={false}
      breakpoints={{
        640: {
          slidesPerView: 2,
        },
        979: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1600: {
          slidesPerView: 5,
        },
        2000: {
          slidesPerView: MAX_PER_ROW,
        },
      }}
      modules={[Mousewheel, Pagination, Navigation]}
    >
      {experiment.get("show_thumbnail_badges", false)
        ? data
            .map(applyHotNewBadge)
            .map(applyAllStarBadge(stats))
            .map((item: IContentItem, index: number) => (
              <StyledSwiperSlide key={index}>
                <ContentItem
                  item={item}
                  title={title}
                  isMontage={isMontageFeed}
                />
              </StyledSwiperSlide>
            ))
        : data.map((item: IContentItem, index: number) => (
            <StyledSwiperSlide key={index}>
              <ContentItem
                item={item}
                title={title}
                isMontage={isMontageFeed}
              />
            </StyledSwiperSlide>
          ))}
    </Swiper>
  );
};

export { ContentFeed };
