import styled from "styled-components";
import { Button } from "../../../../Components/Button";
import { useHistory } from "react-router-dom";
import { TComp, TLeaderboards } from "../../@types";
import { useUser } from "../../../../Hooks/useUser";
import { CampaignSlugViewModel } from "../../Views/[slug]/ViewModel";

export const JoinCampaignButton = ({
  color,
  slug,
  width,
}: {
  color: string;
  slug: string;
  width?: string;
}) => {
  const history = useHistory();
  const { allstarUser } = useUser();
  const { exists, joinLeaderboard } = CampaignSlugViewModel(slug);

  return (
    <StyledButton
      size="default"
      width={width}
      color={color}
      onClick={async () => {
        if (exists?.exists)
          window.rudderanalytics.track("Competition Widget - Visit Click", {});
        else
          window.rudderanalytics.track("Competition Widget - Join Click", {});

        if (!allstarUser.loggedIn)
          return history.push(`/login?next=${window.location.pathname}`);

        await joinLeaderboard({ variables: { campaign: slug } });

        return history.push(`/competitions/${slug}/play`);
      }}
    >
      {exists?.exists ? "Visit Competition" : "Join Competition"}
    </StyledButton>
  );
};
const StyledButton = styled(Button)<{ color: string; width: string }>`
  color: ${({ theme }) => theme.colors.ice};
  z-index: 2;
  font-size: ${({ theme }) => theme.text.subheader.fontSize};

  width: ${(props) => props.width || "200px"};
  background: ${(props) => props.color};

  :hover {
    background: ${(props) => props.color};
    filter: brightness(90%);
  }
`;
