import { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { useGateValue } from "@statsig/react-bindings";

import { Featured } from "./Featured";
import { ContentFeed } from "./ContentFeed";
import { SuggestedFeed } from "./SuggestedFeed";
import { HomepageWrapper } from "./styledComponents";
import { HomepageViewModel } from "./ViewModel";
import { ESupportedGames } from "../../@types";
import { GAME_TO_ICON_MAP } from "../../Components/GameSelector";
import { CampaignWidget } from "../Competitions/Components/Widget";
import { Wrapped } from "../Wrapped";
import { usePortal } from "../../Hooks/usePortal";

interface IHomepageProps {
  wrappedPeriod?: string;
}

const Homepage = ({ wrappedPeriod }: IHomepageProps) => {
  const { contentFeeds, contentFeedsLoading } = HomepageViewModel();
  const wrappedEnabled = useGateValue("wrapped_2024");
  const closeModal = () => {
    setWrappedPortalOpen(false);
  };
  const {
    portalOpen: wrappedPortalOpen,
    setPortalOpen: setWrappedPortalOpen,
    Portal: WrappedPortal,
  } = usePortal(
    <Wrapped period={wrappedPeriod} closeModal={closeModal} />,
    "root",
  );

  useEffect(() => {
    if (wrappedPeriod === "2024") {
      wrappedEnabled
        ? setWrappedPortalOpen(true)
        : (window.location.href = "/");
    }
  }, [setWrappedPortalOpen, wrappedEnabled, wrappedPeriod]);

  return (
    <HomepageWrapper>
      <HomepageContainer>
        {wrappedPortalOpen && WrappedPortal}
        <Featured />
        <CampaignWidget />
        <ContentFeed
          title="For You"
          data={contentFeeds?.forYou?.nodes || contentFeeds?.forYou}
          loading={contentFeedsLoading}
        />
        <ContentFeed
          title="Counter-Strike 2"
          data={contentFeeds?.cs2?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.CS2]}
        />
        <SuggestedFeed />
        <ContentFeed
          title="Popular Montages"
          data={contentFeeds?.montages?.data}
          loading={contentFeedsLoading}
          isMontageFeed={true}
        />
        <ContentFeed
          title="League of Legends"
          data={contentFeeds?.league?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.LOL]}
        />
        <ContentFeed
          title="Fortnite"
          data={contentFeeds?.fortnite?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.FORTNITE]}
        />
        <ContentFeed
          title="Dota 2"
          data={contentFeeds?.dota2?.data}
          loading={contentFeedsLoading}
          icon={GAME_TO_ICON_MAP[ESupportedGames.DOTA2]}
        />
      </HomepageContainer>
    </HomepageWrapper>
  );
};

const HomepageContainer = styled(Flex)`
  flex-direction: column;
  gap: 32px;
  margin-top: 36px;
  max-width: 2480px;
  padding-bottom: 80px;
  width: 100vw;
`;

export default Homepage;
