import { URLS } from "../Maps";

const excludedPaths = [
  URLS.REMIX,
  URLS.STUDIO,
  URLS.OVERWOLF,
  URLS.ALLSTAR_GAMERS_CLUB,
  "partner/claim",
  "partner/signup",
  "mobile-app-link",
  "authenticate/mobile",
  "iframe",
];

const pathExcludesNavigation = (path: string) => {
  return (
    excludedPaths.includes(path) ||
    path.match("signup") ||
    path.match("register") ||
    path.match("login") ||
    path.match("reset") ||
    path.match("register") ||
    path.match("setup") ||
    path.match("oauth") ||
    path.match("remix")
  );
};

export { pathExcludesNavigation };
