import { ReactNode } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { BsFillDiamondFill } from "react-icons/bs";
import { FaMagic, FaMobile } from "react-icons/fa";
import { RiVipDiamondFill } from "react-icons/ri";
import {
  BiSolidAlarmExclamation,
  BiSolidMessageSquareEdit,
} from "react-icons/bi";

import { Button } from "../../Button";
import { NAV_LINKS } from "../../Sidebar/utils";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

type TUpgradeTier = "PRO" | "CREATOR_PLAT";

interface ITierItem {
  icon: ReactNode;
  text: string;
}

const UpgradeCard = ({ currentTier }: { currentTier?: string }) => {
  const tierData = {
    PRO: {
      title: "Allstar Pro",
      buttonText: "Get Pro",
      perks: [
        { text: "Unlimited Remixes", icon: <StyledFaMagic /> },
        { text: "Rare Creator Cards", icon: <StyledBsFillDiamondFill /> },
        {
          text: "Early Access Features",
          icon: <StyledBiSolidAlarmExclamation />,
        },
        {
          text: "Montage Customizations",
          icon: <StyledBiSolidMessageSquareEdit />,
        },
      ],
    },
    CREATOR_PLAT: {
      title: "Allstar Platinum",
      buttonText: "Get Platinum",
      perks: [
        { text: "Unlimited Mobile Clips", icon: <StyledFaMobile /> },
        { text: "Legendary Creator Cards", icon: <StyledRiVipDiamondFill /> },
        {
          text: "Early Access Features",
          icon: <StyledBiSolidAlarmExclamation />,
        },
        {
          text: "Platinum Concierge Service",
          icon: <StyledBiSolidMessageSquareEdit />,
        },
      ],
    },
  };

  if (!currentTier) return <></>;

  const nextTier = (function () {
    switch (currentTier) {
      case "FREE":
        return "PRO";
      case "TRIAL":
      case "PRO":
      case "PRO_PLUS":
        return "CREATOR_PLAT";
      default:
        return;
    }
  })();

  if (!nextTier) return <></>;

  return (
    <StyledCard tier={nextTier}>
      <Text>Unlock the next tier:</Text>
      <Text color="ice" fontSize={7} fontWeight={700}>
        {tierData[nextTier].title}
      </Text>
      <Text color="ice" fontWeight={500}>
        $5.99/month
      </Text>
      <PerksSection>
        {tierData[nextTier]?.perks.map((item: ITierItem, index: number) => (
          <ListItem key={index}>
            {item.icon}
            <Text>{item.text}</Text>
          </ListItem>
        ))}
        <Text color="ice" fontWeight={500}>
          ...And More
        </Text>
      </PerksSection>
      <StyledButton
        size="small"
        mt={2}
        tier={nextTier}
        to={NAV_LINKS.upgrade}
        data-close="true"
      >
        {tierData[nextTier].buttonText}
      </StyledButton>
    </StyledCard>
  );
};

const PerksSection = styled(Flex)`
  flex-direction: column;
  gap: 6px;
  margin: 20px 0;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    display: none;
  }
`;

const iconStyle = css`
  color: ${({ theme }) => theme.colors.ice};
`;

const platinumStyle = css`
  background-image: linear-gradient(130.44deg, #1394c8 6.35%, #824cb0 83.2%),
    url(${REACT_APP_S3_CLIP_BUCKET}/static/web/brand/upgradeLogo.png);
`;

const proStyle = css`
  background-image: url(${REACT_APP_S3_CLIP_BUCKET}/static/web/brand/upgradeLogo.png),
    linear-gradient(227.35deg, #1278a1 -4.11%, #4dbd94 100%);
`;

const StyledButton = styled(Button)<{ tier: TUpgradeTier }>`
  background-color: ${({ theme }) => theme.colors.ice};
  color: ${(props) => (props.tier === "PRO" ? props.theme.colors.premium : "")};
  color: ${(props) =>
    props.tier === "CREATOR_PLAT" ? props.theme.colors.legendary : ""};

  :hover {
    background-color: ${({ theme }) => theme.colors.iceAlpha75};
  }
`;

const ListItem = styled(Flex)`
  align-items: center;
  color: ${({ theme }) => theme.colors.ice};
  font-weight: 500;
  gap: 10px;
`;

const StyledFaMobile = styled(FaMobile)`
  ${iconStyle}
`;

const StyledFaMagic = styled(FaMagic)`
  ${iconStyle}
`;

const StyledBsFillDiamondFill = styled(BsFillDiamondFill)`
  ${iconStyle}
`;

const StyledRiVipDiamondFill = styled(RiVipDiamondFill)`
  ${iconStyle}
`;

const StyledBiSolidAlarmExclamation = styled(BiSolidAlarmExclamation)`
  ${iconStyle}
`;

const StyledBiSolidMessageSquareEdit = styled(BiSolidMessageSquareEdit)`
  ${iconStyle}
`;

const StyledCard = styled(Box)<{ tier: TUpgradeTier }>`
  ${(props) => (props.tier === "PRO" ? proStyle : "")};
  ${(props) => (props.tier === "CREATOR_PLAT" ? platinumStyle : "")};

  background-position: right top;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-bottom: 16px;
  padding: 20px;
`;

export { UpgradeCard };
