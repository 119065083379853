import { useQuery } from "@apollo/client";

import { WRAPPED } from "MVVM/GraphQL/wrapped";

const WrappedViewModel = ({ username }: { username: string }) => {
  const { data, error, loading } = useQuery(WRAPPED, {
    fetchPolicy: "cache-and-network",
    variables: {
      username,
    },
  });

  return {
    data,
    error,
    loading,
  };
};

export { WrappedViewModel };
