import { Route } from "react-router-dom";
import { lazy } from "react";

import About from "../pages/about/index";
import Brand from "../pages/brand/index";
import Culture from "../pages/culture/index";
import Information from "../pages/legal/information";
import Privacy from "../pages/legal/privacy";
import Terms from "../pages/legal/terms";
import VulnerabilityDisclosurePolicy from "../pages/legal/vulnerability-disclosure-policy";
import MasterServicesAgreement from "../pages/legal/masterServicesAgreement";

import { getChunkable } from "./utils";

const LazyPartners = lazy(() => import("../MVVM/Views/Partners"));
const LazyAllstarIO = lazy(() => import("../MVVM/Views/IO"));

const staticPages = [
  <Route key="partners" path="/partners" render={getChunkable(LazyPartners)} />,
  <Route
    exact
    key="terms-of-use"
    path="/legal/terms-of-use"
    component={Terms}
  />,
  <Route
    exact
    key="privacy-policy"
    path="/legal/privacy-policy"
    component={Privacy}
  />,
  <Route exact key="about" path="/about" component={About} />,
  <Route exact key="careers" path="/careers" component={Culture} />,
  <Route exact key="brand" path="/brand" component={Brand} />,
  <Route
    exact
    key="vulnerability-disclosure-policy"
    path="/legal/vulnerability-disclosure-policy"
    component={VulnerabilityDisclosurePolicy}
  />,
  <Route
    exact
    key="ad-terms-and-conditions"
    path="/legal/ad-terms-and-conditions"
    component={getChunkable(LazyAllstarIO, {})}
  />,
  <Route
    exact
    key="do-not-sell-my-personal-information"
    path="/legal/do-not-sell-my-personal-information"
    component={Information}
  />,
  <Route
    exact
    key="master-services-agreement"
    path="/legal/allstar-gaming-master-services-agreement"
    component={MasterServicesAgreement}
  />,
];

export { staticPages };
