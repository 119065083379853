import { Flex, Text } from "rebass/styled-components";

import { GAME_TO_ICON_MAP } from "../../../../Components/GameSelector";
import { dateFormatWithDistanceSuffix } from "../../../../Utilities/Clip";
import { ESupportedGames } from "../../../../@types";
interface IPlayedTogetherProps {
  playedGameId: ESupportedGames;
  lastPlayedAt: string;
}

const PlayedTogether = ({
  playedGameId,
  lastPlayedAt,
}: IPlayedTogetherProps) => {
  return (
    <Flex>
      {GAME_TO_ICON_MAP[playedGameId]}
      <Text fontSize="1">
        {dateFormatWithDistanceSuffix(lastPlayedAt).replace(/month/, "mo")}
      </Text>
    </Flex>
  );
};

export { PlayedTogether };
