import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

export const Container = styled(Box)`
  border: 8px solid #22242e;
  border-radius: 16px;
  width: 700px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    width: 500px;
  }

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 90vw;
  }
`;

export const YearEndWrapContainer = styled(Flex)<{ background: string }>`
  align-items: center;
  background-color: #22242e;
  background-image: url(${(props) => props.background});
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex-direction: column;
  padding: 30px 60px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 30px 20px;
  }
`;
