import { ReactNode, useState } from "react";
import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { analytics } from "../../Analytics";

const Expandable = ({
  children,
  sectionName,
}: {
  children: ReactNode | ReactNode[];
  sectionName?: string;
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const handleClick = () => {
    analytics.track("Expandable Section Expanded", {
      sectionName: sectionName || "",
    });

    setExpanded(!expanded);
  };

  return (
    <>
      <ExpandableBox expanded={expanded}>{children}</ExpandableBox>
      <ExpandableDivider>
        <StyledPill onClick={handleClick}>
          <Text fontSize="1" color="chalk">
            {expanded ? "See Less" : "See More"}
          </Text>
        </StyledPill>
      </ExpandableDivider>
    </>
  );
};

const ExpandableBox = styled(Box)<{ expanded: boolean }>`
  visibility: ${(props) => (props.expanded ? "visible" : "hidden")};
  opacity: ${(props) => (props.expanded ? "1" : "0")};
  height: ${(props) => (props.expanded ? "auto" : "0")};
  transition:
    visibility 0s,
    opacity 0.5s ease-out;
`;

const ExpandableDivider = styled(Flex)`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    ${({ theme }) => theme.colors.chalkAlpha20} calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );
  justify-content: center;
  margin-top: 6px;
  width: 100%;
`;

const StyledPill = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.gray850};
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  border-radius: 20px;
  cursor: pointer;
  text-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 5px 18px;

  :hover {
    background: #182533;
  }

  > div {
    flex-wrap: nowrap;
  }
`;

export { Expandable };
