import React, { useEffect, useRef, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";

const Tooltip = (props) => {
  const parentRef = useRef(); // this ref is specific to the entire dropdown component. Used to detect clicks within it.
  const childRef = useRef(); // this ref is specific to the children passed into the drop down component. These children are most likely the first thing the user clicks to open the dropdown.
  const outsideControl = props.outsideControl;
  const [isVisible, setIsVisible] = useState(!!props.isVisible);
  const [hideState, setHideState] = useState(true);
  const action = props.type === "hover" ? "mouseover" : "click";

  const handleAction = ({ target }) => {
    if (parentRef?.current?.contains(target)) {
      if (
        childRef.current.contains(target) &&
        childRef.current.state &&
        action === "click"
      ) {
        return setIsVisible(false); // If we clicked the child component, and the dropdown is visible. Hide it.
      }

      if (
        !childRef.current.contains(target) &&
        target.closest("a")?.dataset?.close &&
        action === "click"
      ) {
        return setIsVisible(false);
      }

      return setIsVisible(true);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (hideState) return;
    childRef.current.state = isVisible;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    setHideState(props.hide);
  }, [props.hide]);

  useEffect(() => {
    if (outsideControl) return;
    window.addEventListener(action, handleAction);
    return () => {
      window.removeEventListener(action, handleAction);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]); //eslint-disable-line

  //added this to allow for outside control of hiding dropdown
  useEffect(() => {
    if (hideState) return;
    if (!outsideControl) {
      setIsVisible((state) => {
        return state ? props.isVisible : state;
      });
    } else {
      setIsVisible(props.isVisible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible, hideState]);

  useEffect(() => {
    if (action !== "click" || !isVisible || !props.timeout) return;
    const timer = setTimeout(() => {
      setIsVisible(!isVisible);
    }, 3000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, props.timeout, isVisible]);

  if (hideState) return <>{props.children}</>;

  return (
    <TooltipContainer
      ref={parentRef}
      className={props.className}
      width={props.width ? props.width : ""}
    >
      <Box
        ref={(ref) => {
          childRef.current = ref;
        }}
      >
        {props.children}
      </Box>

      {isVisible && (
        <ArrowDropdown
          style={{ zIndex: 10000 }}
          variant={props.theme}
          position={props.position}
          align={props.align}
          wrapText={props.wrapText}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {props.tooltipContent}
        </ArrowDropdown>
      )}
    </TooltipContainer>
  );
};

const TooltipContainer = styled(Box)`
  position: relative;
`;

const ArrowDropdown = styled(Flex)`
  position: absolute;
  z-index: 10;
  white-space: ${(props) => (props.wrapText ? false : "nowrap")};
  ${(props) => props.position}
`;

Tooltip.defaultProps = {
  theme: "menuDropdown",
  align: "right",
  type: "hover",
  wrapText: false,
  timeout: false,
};

export { Tooltip };
