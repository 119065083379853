import { useLazyQuery, useMutation } from "@apollo/client";
import { Playlist, PlaylistInput } from "./@types";
import { MUTATE_PLAYLIST, PLAYLIST_REQUEST } from "../../GraphQL/clipFeed";
import { useSetRecoilState } from "recoil";
import { PinnedClipsAtom } from "../../State/clip";

export const PinnableViewModel = ({ userId }: { userId: string }) => {
  const setPinnedClips = useSetRecoilState(PinnedClipsAtom);
  const [getPlaylists] = useLazyQuery(PLAYLIST_REQUEST, {
    fetchPolicy: "cache-and-network",
    onError(error) {},
  });
  const [mutatePlaylist] = useMutation<Playlist, PlaylistInput>(
    MUTATE_PLAYLIST,
    {
      ignoreResults: true,

      onCompleted: () => {
        getPlaylists({ variables: { userId: userId } }).then((response) => {
          if (response?.data?.usersPlaylists?.playlists?.length > 0) {
            const desktopPlaylist =
              response?.data?.usersPlaylists?.playlists?.find(
                (playlist: { name: string }) => playlist.name === "DEFAULT",
              );

            setPinnedClips(desktopPlaylist?.clips);
          }
        });
      },
    },
  );
  const editPlaylist = async (clipId: string, type: "ADD" | "REMOVE") => {
    const variables =
      type === "ADD"
        ? {
            isAddRemove: true,
            isAdd: true,
            playlistName: "DEFAULT",
            isMobileReq: false,
            clipId: clipId,
          }
        : {
            isAddRemove: true,
            isAdd: false,
            playlistName: "DEFAULT",
            isMobileReq: false,
            clipId: clipId,
          };
    return mutatePlaylist({
      variables: variables,
    });
  };

  return { editPlaylist };
};
