import { Flex, Text } from "rebass/styled-components";
import { Avatar } from "../../../Components/Avatar";
import { dateFormatWithDistanceSuffix } from "../../../Utilities/Clip";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
export const FeaturedUser = ({
  avatar,
  username,
  userId,
  views,
  createdDate,
}: {
  avatar?: string;
  username?: string;
  views?: number;
  createdDate?: string;
  userId?: string;
}) => {
  const history = useHistory();
  if (!avatar || !username || !views || !createdDate) return null;

  const goToUsersProfile = () => {
    history.push(`/profile?user=${userId}`);
  };
  return (
    <Flex style={{ gap: "8px", alignItems: "center" }}>
      <AvatarContainer onClick={goToUsersProfile}>
        <Avatar image={avatar} size="tiny" />
      </AvatarContainer>
      <Flex flexDirection="column">
        <Username variant="text.labelMedium" onClick={goToUsersProfile}>
          {username}
        </Username>
        <Flex style={{ gap: "4px" }} color="chalk">
          <Text variant="text.labelXSmall">{views} views</Text>
          <Text variant="text.labelXSmall">&bull;</Text>
          <Text variant="text.labelXSmall">
            {dateFormatWithDistanceSuffix(createdDate)}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Username = styled(Text)`
  &:hover {
    color: ${({ theme }) => theme.colors.envy};
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
`;
const AvatarContainer = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;
