import { Flex, Image } from "rebass/styled-components";
import styled from "styled-components";
import { BiCog, BiSupport, BiLogOut } from "react-icons/bi";
import { MdTranslate } from "react-icons/md";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";

import {
  navLinkActive,
  NavLinkText,
  sidebarIconStyle,
  StyledDivider,
} from "../../../Sidebar/styledComponents";
import { useUser } from "../../../../Hooks/useUser";
import { NavLink, navLinkStyle } from "../../../Sidebar";
import { NAV_LINKS } from "../../../Sidebar/utils";
import { LanguagesDropdown } from "../../../../../components/organisms/nav/languagesDropDown";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";
import { UpgradeCard } from "../../UpgradeCard";
import { UserCard } from "../../UserCard";

const DropdownNav = () => {
  const { allstarUser, logout } = useUser();
  const { REACT_APP_S3_CLIP_BUCKET } = process.env;
  const setAllstarModalState = useSetRecoilState(AllstarModalState);
  const userGroupType = allstarUser.user?.userGroup?.type;
  const { t } = useTranslation("translation", {
    keyPrefix: "nav",
  });

  return (
    <DropdownContainer>
      <UserCard />
      <UpgradeCard currentTier={userGroupType} />
      <DropdownNavContainer>
        <NavLink
          to={NAV_LINKS.accountSettings}
          $isActive={false}
          data-close="true"
        >
          <StyledBiCog />
          <NavLinkText>
            {t("top.accountSettings", "Account Settings")}
          </NavLinkText>
        </NavLink>
        <PseudoNavLink
          onClick={() => {
            window.rudderanalytics.track("Signature - Open Modal", {
              where: "Nav Menu",
            });
            setAllstarModalState({
              isOpen: ModalType.SignatureSelector,
              functions: {},
              data: {},
            });
          }}
        >
          <Image src={`${REACT_APP_S3_CLIP_BUCKET}/static/loadouts/sig.svg`} />
          <NavLinkText>
            {t("top.manageSignatures", "Manage Signature")}
          </NavLinkText>
        </PseudoNavLink>
        <PseudoNavLink onClick={() => window.open(NAV_LINKS.support, "_blank")}>
          <StyledBiSupport />
          <NavLinkText>{t("top.support", "Support")}</NavLinkText>
        </PseudoNavLink>
        <StyledDivider />
        <PseudoNavLink>
          <StyledMdTranslate />
          <NavLinkText>
            <Languages>
              <LanguagesDropdown />
            </Languages>
          </NavLinkText>
        </PseudoNavLink>
        <StyledDivider />
        <PseudoNavLink onClick={() => logout("Site Header logged out.")}>
          <StyledBiLogOut />
          <NavLinkText>{t("top.logout", "Logout")}</NavLinkText>
        </PseudoNavLink>
      </DropdownNavContainer>
    </DropdownContainer>
  );
};

const Languages = styled(Flex)`
  margin-left: -12px;

  select {
    color: ${({ theme }) => theme.colors.chalk};
    font-family: "Barlow";
    font-size: ${({ theme }) => theme.fontSizes[2]};
    font-weight: 500;
    margin: 0;
    padding: 2px 12px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.envy};
  }

  svg {
    padding-left: 30px;
  }
`;

const DropdownNavContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const PseudoNavLink = styled(Flex)`
  ${navLinkStyle}

  :hover {
    ${navLinkActive}
  }
`;

const StyledBiCog = styled(BiCog)`
  ${sidebarIconStyle}
`;

const StyledBiSupport = styled(BiSupport)`
  ${sidebarIconStyle}
`;

const StyledBiLogOut = styled(BiLogOut)`
  ${sidebarIconStyle}
`;

const StyledMdTranslate = styled(MdTranslate)`
  ${sidebarIconStyle}
`;

const DropdownContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  padding: 6px;
  width: 304px;
`;

export { DropdownNav };
