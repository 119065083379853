import { Flex } from "rebass/styled-components";
import styled from "styled-components";

export const RoundedButton = styled<any>(Flex)`
  border-radius: 999px;
  flex-direction: row;
  padding: 10px 18px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  flex-shrink: 0;
  background-color: ${(props: any) => {
    return props.theme.colors.navy;
  }};
  border: 2px solid transparent;
  &:hover {
    cursor: pointer;
    background-color: ${(props: any) => {
      return props.theme.colors.envyAlpha05;
    }};
    border: 2px solid
      ${(props: any) => {
        return props.theme.colors.darkerEnvy;
      }};
  }
  ${(props) => {
    if (props.active) {
      return `
          background-color: ${props.theme.colors.envyAlpha05};
          border: 2px solid ${props.theme.colors.darkerEnvy};
  `;
    }
  }}

  @media (max-width: ${(props: any) => props.theme.breaks.medium}) {
    padding: 6px 14px;
    font-size: 12px;
  }
`;

export const RoundedRectangularButton = styled<any>(Flex)`
  border-radius: 9px;
  flex-direction: row;
  padding: 10px 14px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  min-width: 100%;
  color: ${(props: any) => {
    return props.theme.colors.ice;
  }};
  background-color: ${(props: any) => {
    return props.theme.colors.nedLight;
  }};

  &:hover {
    cursor: pointer;
    background-color: ${(props: any) => {
      return props.theme.colors.midnightAlpha50;
    }};
    color: ${(props: any) => {
      return props.theme.colors.envy;
    }};
  }
  ${(props) => {
    if (props.active) {
      return `
          background-color: ${props.theme.colors.midnightAlpha50};
          color: ${props.theme.colors.envy};
          


  `;
    }
  }}
`;
