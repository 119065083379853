import { Suspense } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";
import { Flex } from "rebass";

import LoadingSVG from "../loading.svg";
import { URLS } from "../MVVM/Utilities/Maps";

const FallbackSVG = () => <StyledImage src={LoadingSVG} alt="loading" />;

const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <div style={{ margin: "auto" }}>
      <Error>Failed to load data. Please reload the page and try again</Error>
      <button
        onClick={() => {
          resetErrorBoundary();
        }}
      >
        Try again
      </button>
    </div>
  );
};

export const MINIMAL_ROUTES = [
  "/referred",
  "/overwolf",
  "/allstar/gamersclub",
  "/mobile-app-link/terms-of-use",
  "/mobile-app-link/privacy-policy",
  "/mobile-app-link/login",
  "/mobile-app-link/signup",
  "/mobile-app-link/faq",
  "/login",
  "/signup",
  "/register",
  "/reset",
];

const LoadingPage = () => (
  <StyledLoadingPage>
    <FallbackSVG />
  </StyledLoadingPage>
);

export const getChunkable = (Component, extraProps) => (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<LoadingPage />}>
      <Component {...props} {...extraProps} />
    </Suspense>
  </ErrorBoundary>
);

const StyledImage = styled.img`
  max-width: 50px;
  margin: auto;
`;

const StyledLoadingPage = styled(Flex)`
  align-items: center;
  height: 100vh;
  justify-content: center;
`;
