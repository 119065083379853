import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Title3 } from "../../Components/Text";

export const HomepageWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.ned};
  justify-content: center;
  width: 100%;
  padding: 0 60px;

  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin: 0;
    padding: 0 24px;
  }
`;

export const FeaturedCardWrapper = styled(Flex)`
  max-width: 640px;
  width: 640px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
    flex-direction: column;
  }

  > :last-child {
    gap: 20px;
    height: auto;
    width: 100%;
  }
`;

export const SectionTitle = styled(Title3)`
  font-weight: 700;
`;

export const VideoContainer = styled.div<{ clickable: boolean }>`
  background-size: cover;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const VideoContainerFeed = styled(VideoContainer)`
  width: 100%;
  z-index: 2;
  border-radius: 9px;
`;

export const VideoContent = styled(Flex)`
  background: ${({ theme }) => theme.colors.midnight};
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-width: 240px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    max-width: 100%;
    width: 100%;
  }
`;

export const FeaturedContainer = styled(Flex)``;

export const StyledSlider = styled(Swiper)`
  width: 100%;
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  align-items: center;
  display: flex;
  justify-content: center;
  width: auto;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    width: 100%;
  }
`;
