import { gql } from "@apollo/client";
import { ESupportedGames } from "../@types";

const HOMEPAGE_CLIP_FIELDS = gql`
  fragment HomepageClipFields on Clips {
    _id
    clipImageThumb
    clipSnapshot
    clipLink
    clipPreviewPath
    clipTitle
    createdDate
    game
    shareId
    views
    score {
      value
    }
    partner {
      useCase
    }
    cf {
      hls
    }
    clipProperties {
      _id
      key
      value
      displayType
    }
    user {
      _id
      avatar
      username
    }
  }
`;

const HOMEPAGE_CLIP_FIELDS_NEW = gql`
  fragment HomepageClipFieldsNew on Clip {
    thumbnailStandardUrl: thumbnailUrl(style: STANDARD)
    thumbnailOverlayUrl: thumbnailUrl(style: OVERLAY)
    previewUrl
    title
    createdAt
    shareId
    views
    score
    user {
      _id
      avatarUrl
      username
    }
  }
`;

export const HOMEPAGE_CONTENT_FEED = (recommended: boolean) => gql`
  ${HOMEPAGE_CLIP_FIELDS}
  ${HOMEPAGE_CLIP_FIELDS_NEW}

  query HomepageContentFeed($search: SearchClips!, $limit: Int, $startDate: String!) {
    montages: montages(search: views, limit: $limit, dateRange: { start: $startDate }) {
      data {
        _id
        views
        shareId
        username
        clipImageSource
        clipImageThumb
        clipSnapshot
        clipLink
        clipTitle
        userClipTitle
        createdDate
        user {
          _id
          avatar
          username
        }
      }
    }

    forYou: ${
      recommended
        ? "clipsRecommended(limit: $limit)"
        : "clipsNew(first: $limit)"
    } {
      ${
        recommended
          ? "...HomepageClipFieldsNew"
          : "nodes { ...HomepageClipFieldsNew }"
      }
    }

    cs2: clips(search: $search, limit: $limit, game: ${ESupportedGames.CS2}) {
      data {
        ...HomepageClipFields
      }
    }

    league: clips(search: $search, limit: $limit, game: ${
      ESupportedGames.LOL
    }) {
      data {
        ...HomepageClipFields
      }
    }

    fortnite: clips(search: ${"createdDate"}, limit: $limit, game: ${
      ESupportedGames.FORTNITE
    }) {
      data {
        ...HomepageClipFields
      }
    }

    dota2: clips(search: ${"createdDate"}, limit: $limit, game: ${
      ESupportedGames.DOTA2
    }) {
      data {
        ...HomepageClipFields
      }
    }
  }
`;
