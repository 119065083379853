import { gql } from "@apollo/client";

export const WRAPPED = gql`
  query wrappedSummary($username: String!) {
    wrappedSummary(username: $username) {
      clipReactions
      clip {
        id
        shareId
        thumbnailUrl(style: STANDARD)
        title
        url(orientation: LANDSCAPE)
        views
      }
      framesSaved
      gbStored
      hoursSaved
      totalViews
      user {
        avatarUrl
        username
      }
      watchTime
    }
  }
`;
