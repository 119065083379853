import { useIsDesktopApp } from "./MVVM/Hooks/useIsDesktopApp";
import { useIsMobileApp } from "./MVVM/Hooks/useIsMobileApp";

import { Sidebar } from "./MVVM/Components/Sidebar";
import { NavSignup } from "./components/organisms/navSignup";
import { MINIMAL_ROUTES } from "./routes/utils";
import { pathExcludesNavigation } from "./MVVM/Utilities/Navigation/utils";

const Navigation = () => {
  const path = window.location.pathname;
  const isDesktopView = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();

  if (
    pathExcludesNavigation(path) ||
    isDesktopView.isDesktop ||
    isMobileApp.isMobileApp
  )
    return <></>;

  if (MINIMAL_ROUTES.includes(window.location.pathname)) return <NavSignup />;

  return <Sidebar />;
};

export { Navigation };
