import styled from "styled-components";
import { HiPlay } from "react-icons/hi2";
import { Button } from "../Button";

export const Badge = styled.img`
  top: 20px;
  position: absolute;
  right: 18px;
  width: 100px;
  z-index: 2;
`;

export const PlayIcon = styled(HiPlay)`
  width: 43.75%;
  height: 43.75%;
  position: absolute;
  top: 50%;
  left: 22%;
  transform: translateX(-50%) translateY(-50%);
`;

export const SlideButton = styled(Button)`
  border-radius: 20px;
  bottom: 18px;
  box-shadow: -5px 0px 30px rgba(0, 0, 0, 0.5);
  padding: 0 12px;
  position: absolute;
  right: 18px;
  width: max-content;
  z-index: 2;
`;

export const Tag = styled.div<{ absolute?: boolean }>`
  border-radius: 4px;
  font-size: ${({ theme }) => theme.text.subheader.fontSize};
  font-weight: 900;
  margin: 6px;
  padding: 4px 8px;
  position: ${(props) => (props?.absolute ? "absolute" : "static")};
  text-transform: uppercase;
  top: 0;
  z-index: 2;

  &.learn {
    background: ${({ theme }) => theme.colors.royalty};
  }

  &.happening-now {
    background: ${({ theme }) => theme.colors.error};
  }

  &.watch {
    background: ${({ theme }) => theme.colors.alert};
  }

  &.partner {
    background: ${({ theme }) => theme.colors.turbo};
  }

  &.beta {
    background: ${({ theme }) => theme.colors.envy};
  }

  &.compete {
    background: ${({ theme }) => theme.colors.rare};
  }
`;

export const WatchButton = styled(SlideButton)`
  padding-left: 26px;
`;
