import React from "react";
import { Headline, Body } from "../text";
import { Box, Flex } from "rebass/styled-components";
import theme from "../../theme/themeThree";

export function SettingsContainer({ title, subtitle, children, ...rest }) {
  return (
    <Box variant={rest.variant ? rest.variant : "settingsContainer"} {...rest}>
      <Flex flexDirection="row">
        {title ? <Headline mb={subtitle ? 3 : 6} mr={rest.icon ? 3 : 0}>{title}</Headline> : null}
        {rest.icon ? rest.icon : null}
      </Flex>
      {subtitle ? (
        <Body color={rest.subtitleTextColor ? rest.subtitleTextColor : "bodyColor"} mb={theme.margins.body}>
          {subtitle}
        </Body>
      ) : null}
      {children}
    </Box>
  );
}
