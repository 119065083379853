import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { Large3, Body } from "../../../../Components/Text";

export const PageWrapper = styled.div`
  margin-left: 100px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-left: 0px;
  }
`;

export const CountdownDate = styled(Flex)`
  color: #ffffff;
  z-index: 2;
  position: relative;
`;

export const StyledBody = styled(Large3)`
  font-weight: 600;
  font-size: 36px;
  color: #7b5dce;
  padding-right: 15px;
  color: ${(props) => props.color};
`;

export const StyledPage = styled.div`
  padding: 20px 0 90px 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 20px;
`;

export const StyledHeadline = styled(Body)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.text.large2.fontSize};
  font-weight: ${({ theme }) => theme.text.large1.fontWeight};
  line-height: normal;
  font-weight: 600;
`;

export const StyledSubHeadline = styled(StyledHeadline)`
  font-size: 1.75em;
  font-weight: 600;
`;

export const StyledDivider = styled(Box)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.chalkAlpha40};
  height: 1px;
  margin: 36px 0;
`;
