import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { HOMEPAGE_FEATURED } from "../../../GraphQL/homepageFeatured";
import { IFeaturedItem } from "./@types";
import { ModalType } from "../../../@types";

interface IPromotion {
  default: boolean;
  image: string;
  name: string;
  tag: string;
  link: string;
  buttonCopy?: string;
  thumbnail?: string;
  buttonAction?: ModalType;
  external?: boolean;
  clip?: {
    shareId: string;
  };
}

const FeaturedViewModel = () => {
  const { data, error, loading } = useQuery(HOMEPAGE_FEATURED, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const [featuredContent, setfeaturedContent] = useState<IFeaturedItem[]>([]);
  const [slideCount, setSlideCount] = useState(0);

  useEffect(() => {
    if (!data) return;

    const featuredItems: IFeaturedItem[] = [];

    const { clipOfTheDay, montageOfTheWeek, promotions } = data;
    const activePromotions = promotions.activeCarouselPromos.filter(
      (promotion: IPromotion) => !promotion.default,
    );

    /* If we have non-default promos, those are "active" and we only show those
     Otherwise, we show the rest as defaults  */
    const featuredPromotions = activePromotions
      ? activePromotions
      : promotions.activeCarouselPromos;

    if (featuredPromotions.length) {
      featuredPromotions.forEach((promotion: IPromotion) => {
        const videoFormat = promotion.image.split(".").splice(-1)[0];

        featuredItems.push({
          title: promotion.name,
          hlsSrc: promotion.image,
          mp4Src: videoFormat === "mp4" ? promotion.image : undefined,
          type: "promotion",
          link: promotion.link,
          tag: promotion.tag,
          buttonCopy: promotion.buttonCopy,
          external: promotion.external,
          buttonAction: promotion.buttonAction,
          shareId: promotion.clip?.shareId,
          thumbnail: promotion.thumbnail,
        });
      });
    }

    if (clipOfTheDay)
      featuredItems.push({
        title: clipOfTheDay.clipTitle || clipOfTheDay.title,
        hlsSrc: clipOfTheDay?.cf?.hls,
        mp4Src: clipOfTheDay.clipLink,
        type: "cotd",
        link: `/clip?clip=${clipOfTheDay.shareId}`,
        tag: "WATCH",
        buttonCopy: "Watch",
        shareId: clipOfTheDay.shareId,
        thumbnail: clipOfTheDay.clipSnapshot,
        avatar: clipOfTheDay.user?.avatar,
        username: clipOfTheDay.user?.username,
        userId: clipOfTheDay.user?._id,
        views: clipOfTheDay.views,
        createdDate: clipOfTheDay.createdDate,
      });

    setfeaturedContent(featuredItems);

    if (montageOfTheWeek)
      featuredItems.push({
        title: montageOfTheWeek.clipTitle || montageOfTheWeek.title,
        hlsSrc: montageOfTheWeek?.cf?.hls,
        mp4Src: montageOfTheWeek.clipLink,
        type: "motw",
        link: `/montage?montage=${montageOfTheWeek.shareId}`,
        tag: "WATCH",
        buttonCopy: "Watch",
        thumbnail: montageOfTheWeek.clipSnapshot,
        avatar: montageOfTheWeek.user?.avatar,
        username: montageOfTheWeek.user?.username,
        views: montageOfTheWeek.views,
        createdDate: montageOfTheWeek.createdDate,
        userId: montageOfTheWeek.user?._id,
      });
  }, [data]);

  useEffect(
    () => setSlideCount(featuredContent.length),
    [featuredContent.length],
  );

  return { featuredContent, error, loading, slideCount };
};

export { FeaturedViewModel };
