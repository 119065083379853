import { useQuery } from "@apollo/client";
import { COMPETITION_CLIPS } from "../../../../../GraphQL/competitions";

export const CompetitionClipsViewModel = ({
  cardIds,
}: {
  cardIds: string[];
}) => {
  const { data, loading, error } = useQuery(COMPETITION_CLIPS, {
    variables: {
      limit: 10,
      cardIds,
    },
    skip: !cardIds.length,
  });

  return {
    competitionClips: data?.creatorCardClips,
    loading,
    error,
  };
};
