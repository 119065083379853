import { gql } from "@apollo/client";

export const HOMEPAGE_FEATURED = gql`
  query HomepageFeatured {
    clipOfTheDay {
      _id
      user {
        _id
        admin
        avatar
        userGroup {
          type
        }
        discord {
          avatar
          id
          username
        }
        faceit {
          nickname
        }
        tiktok {
          display_name
          avatar
        }
        scope
        rank
        discordSignUp
        emailVerified
        createdDate
        username
      }
      userClipTitle
      clipTitle
      game
      clipLength
      views
      shareId
      username
      createdDate
      clipImageSource
      clipImageThumb
      clipLinkHLS
      clipLink
      clipSnapshot
      cf {
        hls
        readyToStream
      }
      clipProperties {
        _id
        displayType
        key
        category
        value
      }
    }
    montageOfTheWeek {
      user {
        _id
        admin
        avatar
        userGroup {
          type
        }
        discord {
          username
          id
          avatar
        }
        scope
        rank
        discordSignUp
        emailVerified
        createdDate
        username
      }
      views
      shareId
      username
      createdDate
      clipImageSource
      clipImageThumb
      clipLinkHLS
      clipLink
      clipSnapshot
      clipTitle
      cf {
        hls
        readyToStream
      }
    }
    promotions {
      name
      activeCarouselPromos {
        name
        title
        subtitle
        image
        link
        buttonCopy
        sideGradientCSS
        external
        tag
        buttonAction
        thumbnail
        clip {
          shareId
          cf {
            thumbnail
          }
        }
      }
    }
  }
`;
