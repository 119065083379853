import { Flex, Box, Image, Text } from "rebass/styled-components";
import styled from "styled-components";
import { Body, Footnote, Title3 } from "../Text";
import { AiOutlineStop } from "react-icons/ai";
import { ReactComponent as ProIcon } from "../../../assets/images/pro/new/PRO-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/images/pro/new/PLUS-icon.svg";
import { ReactComponent as PlatinumIcon } from "../../../assets/images/pro/new/PLATINUM-icon.svg";

export const Column = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const SelectionRow = styled(Flex)`
  gap: 15px;

  width: 100%;
  flex-wrap: wrap;
`;

export const SectionHeader = styled(Title3)`
  margin-bottom: 10px;
`;

export const ToggleName = styled(Body)`
  font-size: 18px;
  font-weight: 550;
  padding-top: 2px;
`;

export const ToggleDescription = styled(Footnote)`
  color: ${({ theme }) => theme.colors.chalk};
  font-weight: 500;
`;

export const StyledImage = styled(Image)`
  border: 1px solid transparent;
  border-radius: 4px;
  width: 104px;
  height: 50px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.envy};
  }
  ${({ selected, theme }) =>
    selected && `border:  1px solid ${theme.colors.envy};`}
`;

export const StyledDropdownImage = styled(Image)`
  border-radius: 4px;
  width: 46px;
  height: 24px;
}
`;
export const StyledCountryFlag = styled(Image)`
  border-radius: 9px;
  width: 22px;
  height: 22px;
}

`;

export const NoLoadoutContainer = styled(Flex)`
  width: 104px;
  height: 50px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.envy};
  }
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.navyAlpha20};
  ${({ selected, theme }) =>
    selected && `border: 1px solid ${theme.colors.envy};`}
`;

const ColorContainer = styled(Box)`
  width: 104px;
  height: 50px;

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.envy};
  }
  border-radius: 4px;
  ${({ selected, theme }) =>
    selected && `border: 1px solid ${theme.colors.envy};`}
`;

export const StopIcon = styled(AiOutlineStop)`
  color: $ ${({ theme }) => theme.colors.chalk};
  fill: ${({ theme }) => theme.colors.chalk};
  height: 30px;
  width: 30px;
`;
export const StyledFootnote = styled(Footnote)`
  font-weight: 600;
  font-size: 12px;
`;

export const StyledProBadge = styled(ProIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledPlusBadge = styled(PlusIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledPlatinumBadge = styled(PlatinumIcon)`
  width: 20px;
  height: 20px;
`;
export const PremiumIconPositionWrapper = styled(Box)`
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 1px;
  padding: 2px;
  background-color: ${({ theme }) => theme.colors.darkerNedAlpha50};
  border-bottom-left-radius: 5px;
  pointer-events: none;
`;
export const StyleWrapper = styled(Box)`
  position: relative;
`;

export const TopRightImage = styled(Image)`
  left: 400px;
  top: 64px;
  position: absolute;
  width: 144px;
  @media (max-width: 1535px) {
    display: none;
  }
`;

export const StyledLinkText = styled(Text)`
  color: ${({ theme }) => theme.colors.envy};
  display: inline-block;
  &:hover {
    cursor: pointer;
  }
`;
